<template>
    <div class="message-text">
        <div class="open-chat">
            <div class="noti-title">
                <img class="m-r-8" src="@/assets/images/icons/hi.png" width="24px" height="24px" />
                <span>처음 뵙겠습니다:)</span>
            </div>
            <div class="noti-body">
                이번에 {{ content.name }}님의 프로필과 서류 심사를 담당하게 된 심사팀 유나영입니다.
            </div>
            <div class="noti-body">심사결과는 약 2~3일이 소요되며, 심사가 끝난 후 직접 안내드릴 예정입니다.</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionOpenChatAI',
    props: ['message'],
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
    },
    methods: {},
}
</script>

<style scoped lang="scss">
.open-chat {
    padding: 9px 4px;
    .noti-title {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }
    .noti-body {
        font-size: 14px;
        margin-top: 8px;
        color: #111111;
        line-height: 26px;
    }
}
</style>
